import React from 'react';

import { ReserveWhiskeyPage } from '~/components/views/Whiskey/ReserveWhiskey/ReserveWhiskeyPage';
import AppContainer from '~/containers/AppContainer/AppContainer';
import { useRouter } from 'next/router';
import HeaderMetaTags from '~/components/modules/Header/HeaderMetaTags';

const WhiskeyInvestmentPage: React.FC = () => {
    const router = useRouter();
    return (
        <AppContainer>
            <HeaderMetaTags
                url={`${process.env.NEXT_PUBLIC_HOST_ENV}${router.asPath}`}
                title={'Investing in Whiskey - What to Know | Vinovest'}
                description={
                    'Invest in whiskey with Vinovest. Our latest exit was a batch of high rye bourbon sold for 30.74% above their initial purchase price.'
                }
                canonical
            />
            <ReserveWhiskeyPage />
        </AppContainer>
    );
};

export default WhiskeyInvestmentPage;
